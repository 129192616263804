<template>
  <div class="cultureStoryDetailCarousel">
    <carousel
      :perPageCustom="[
        [0, 1],
        [768, 5]
      ]"
      :autoplay="false"
      :paginationEnabled="false"
      :navigationEnabled="true"
      :navigationNextLabel="'►'"
      :navigationPrevLabel="'◀'"
    >
      <slide v-for="item in itemList" :key="item.title">
        <div class="cultureStoryDetailCarousel__item">
          <a href="#">
          <div
            @click="
              $emit('onClickCarouselImg', {
                title: item.title,
                image: `${baseURL}${item.image}`
              })
            "
            :alt="`${item.title}`"
            class="cultureStoryDetailCarousel__img clickable"
            :title="`${item.title}`"
            :style="`background-image: url(${baseURL}${item.image})`"
          ></div>
          </a>
        </div>
      </slide>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'

export default {
  components: { Carousel, Slide },
  props: {
    itemList: {
      type: Array
    }
  },
  computed: {
    baseURL() {
      return process.env.VUE_APP_WEB_BASE
    }
  }
}
</script>

<style lang="scss">
.cultureStoryDetailCarousel {
  width: 80%;
  margin: 8rem auto;

  &__item {
    padding: 0 0.75rem;
  }

  &__img {
    width: 100%;
    padding-bottom: 75%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  & .VueCarousel-navigation-button {
    color: #d0a805;
  }
}
</style>
