var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cultureStoryDetailCarousel"},[_c('carousel',{attrs:{"perPageCustom":[
      [0, 1],
      [768, 5]
    ],"autoplay":false,"paginationEnabled":false,"navigationEnabled":true,"navigationNextLabel":'►',"navigationPrevLabel":'◀'}},_vm._l((_vm.itemList),function(item){return _c('slide',{key:item.title},[_c('div',{staticClass:"cultureStoryDetailCarousel__item"},[_c('a',{attrs:{"href":"#"}},[_c('div',{staticClass:"cultureStoryDetailCarousel__img clickable",style:(`background-image: url(${_vm.baseURL}${item.image})`),attrs:{"alt":`${item.title}`,"title":`${item.title}`},on:{"click":function($event){return _vm.$emit('onClickCarouselImg', {
              title: item.title,
              image: `${_vm.baseURL}${item.image}`
            })}}})])])])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }