<template>
  <main v-if="storyItem" class="cultureStoryDetail">
    <section class="container">
      <h2 class="cultureStoryDetail__title">{{ storyItem.title }}</h2>
      <h3 v-if="storyItem.describ" class="cultureStoryDetail__subTitle">
        {{ storyItem.describ }}
      </h3>
      <hr class="cultureStoryDetail__hr" />

      <CultureStoryDetailYoutubeCard
        v-if="storyItem.link"
        :item="{ link: storyItem.link }"
      />

      <AlbumItem
        v-for="article in storyItem.article"
        :key="`${article.title}${article.order}`"
        :article="article"
      />
    </section>

    <Carousel
      v-if="storyItem.pictures && storyItem.pictures.length > 0"
      @onClickCarouselImg="onClickCarouselImg"
      :itemList="storyItem.pictures"
    />
    <FullCarouselImg
      v-if="showFullCarouselImg"
      @onClickCloseFullCarouseImg="showFullCarouselImg = false"
      :imgURL="fullCarouselImg"
      :title="fullCarouselTitle"
    />
    <GoBackFooter
      :routeItem="{
        text: '返回礦山故事',
        arrowString: '&#10229;',
        routeObj: { name: 'CultureStory', query: { page,  location }}
      }"
      :secondRouteItem="nextItem"
    />
  </main>
</template>

<script>
import AlbumItem from '@/components/CultureStoryDetail/AlbumItem.vue'
import GoBackFooter from '@/components/GoBackFooter.vue'
import Carousel from '@/components/CultureStoryDetail/Carousel.vue'
import CultureStoryDetailYoutubeCard from '@/components/CultureStoryDetail/CultureStoryDetailYoutubeCard.vue'
import FullCarouselImg from '@/components/CultureStoryDetail/FullCarouselImg.vue'

// Api
import { apiGetMiningStory } from '@/api/webAPI'

export default {
  components: {
    AlbumItem,
    GoBackFooter,
    Carousel,
    CultureStoryDetailYoutubeCard,
    FullCarouselImg
  },
  data() {
    return {
      storyItem: null,
      storyList: [],
      showFullCarouselImg: false,
      fullCarouselImg: '',
      fullCarouselTitle: ''
    }
  },
  computed: {
    location() {
      return this.storyItem.location
    },
    page() {
      const { page } = this.$route.query
      if (!page) return 1
      return page
    },
    nextItem() {
      const currentIndex = this.storyList.findIndex(
        story => story.msId === this.storyItem.msId
      )

      if (currentIndex === this.storyList.length - 1) return null

      return {
        text: '前往下一則故事',
        arrowString: '&#10230;',
        routeObj: {
          name: 'CultureStoryDetail',
          query: { id: this.storyList[currentIndex + 1].msId }
        }
      }
    }
  },
  watch: {
    $route(to, from) {
      this.fetchData()
    }
  },
  methods: {

    onClickCarouselImg(item) {
      this.showFullCarouselImg = true
      this.fullCarouselImg = item.image
      this.fullCarouselTitle = item.title
    },
    async fetchData() {
      const { id } = this.$route.query
      if (!id) this.$router.push({ name: 'CultureStory' })

      // article: (6) [{…}, {…}, {…}, {…}, {…}, {…}]
      // describ: ""
      // image: "upload/mining_story/0bb78b92fd66.jpg"
      // location: "九份"
      // msId: "13"
      // order: "0"
      // title: "在九份，拼貼風景的人"

      const response = (
        await apiGetMiningStory({
          type: 'article',
          id
        })
      ).data.data

      if (response.length === 0) {
        this.$router.push({ name: 'CultureStory' })
      }

      this.showFullCarouselImg = false
      this.fullCarouselImg = ''
      this.fullCarouselTitle = ''
      this.storyItem = response[0]
    },
    async fetchStoryList() {
      const response = (
        await apiGetMiningStory({
          type: 'category'
        })
      ).data.data

      this.storyList = response
    }
  },
  created() {
    if (this.storyList.length === 0) this.fetchStoryList()
    this.fetchData()
  }
}
</script>
<style lang="scss">
.cultureStoryDetail {
  padding-top: 8.3rem;

  &__title,
  &__subTitle {
    text-align: center;
    font-weight: 500;
    font-family: 'Noto Serif TC';
  }

  &__title {
    font-size: 3.2rem;
    line-height: 4.6rem;
    margin-bottom: 1.2rem;
  }

  &__subTitle {
    font-size: 2rem;
    line-height: 2.9rem;
  }

  &__hr {
    border: none;
    width: 100%;
    border-bottom: 1px solid #d0a805;
    margin: 5rem auto;
  }
}
</style>
