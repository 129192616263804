<template>
  <section class="cultureStoryDetailAlbumItem">
    <div
      :alt="`${article.title}`"
      class="cultureStoryDetailAlbumItem__img"
      :style="`background-image: url(${baseURL}${article.image})`"
    ></div>
    <h2 class="cultureStoryDetailAlbumItem__title">{{ article.title }}</h2>
    <p class="cultureStoryDetailAlbumItem__text">
      {{ article.describ }}
    </p>
  </section>
</template>

<script>
export default {
  props: {
    article: {
      type: Object,
      default: () => ({
        describ:
          '「我是九份人，我八十二年前在這邊出生的。」這是胡達華面對鏡頭時所說的第一句話。\r\n\r\n八十二歲的胡達華，是臺灣九份獨有的釘畫藝術家，言談與行動之間充滿活力，氣色紅潤，思考敏捷，也許是創作帶給他的能量，完全不像一位年過八旬的長者。而談起九份的過去，他的記憶深刻鮮明，話語充滿細節，好像那是昨天才發生過的事。他說起自己少年時在礦坑時挑水的故事，說起求學時搭乘「輕便車」來往九份與瑞芳的故事，也說起更早以前，九份還是不夜城時的黃金記憶。',
        image: 'upload/mining_story/ed647242e18d.jpg',
        order: '0',
        title: '胡達華先生個人工作照'
      })
    }
  },
  computed: {
    baseURL() {
      return process.env.VUE_APP_WEB_BASE
    }
  }
}
</script>

<style lang="scss">
.cultureStoryDetailAlbumItem {
  width: 100%;
  max-width: 70rem;
  margin: 4rem auto;

  &__img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding-bottom: 75%;
    margin-bottom: 1.1rem;
  }

  &__title::before {
    content: '';
    width: 1.2rem;
    height: 1.2rem;
    background-color: black;
    display: inline-block;
    margin-bottom: 0.1rem;
  }

  &__title::before {
    margin-right: 0.8rem;
  }

  &__title {
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 200%;
    margin-bottom: 1.2rem;
  }

  &__text {
    font-size: 1.8rem;
    line-height: 200%;
    color: #595757;
    text-align: justify;
  }
}

@media (max-width: 768px) {
  .cultureMapDetailAlbumItem {
    width: 89%;
  }
}
</style>
