<template>
  <div class="fullCarouseImg">
    <div class="fullCarouseImg__container">
      <button
        @click="$emit('onClickCloseFullCarouseImg')"
        title="點擊後關閉放大圖"
        class="fullCarouseImg__closeButton clickable"
      >
        &#10005;
      </button>
      <div
        :alt="`${ title }`"
        class="fullCarouseImg__img"
        :style="`background-image: url(${imgURL})`"
      ></div>
      <h2 class="fullCarouseImg__title">{{ title }}</h2>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imgURL: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true,
      default: '標題'
    }
  }
}
</script>

<style lang="scss">
.fullCarouseImg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  &__container {
    padding: 2rem;
    box-shadow: 0 25px 50px -12px rgb(0 0 0 / 25%);
    background-color: white;
    width: 70%;
    max-width: 76.8rem;
  }

  &__img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 60%;
    padding-bottom: 45%;
    margin: 0 auto;
  }

  &__title {
    width: 60%;
    margin: 0 auto;
    font-size: 1.6rem;
    margin-top: 0.5rem;
  }

  &__closeButton {
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 999px;
    margin-left: auto;
    border: none;
    background-color: #c4c4c4;
  }
}

@media (max-width: 768px) {
  .fullCarouseImg {
    &__container {
      width: 80%;
    }
  }
}
</style>
